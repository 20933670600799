<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <b-col cols="12">
                    <ValidationProvider name="faculty_code" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('faculty')">
                            <faculty-selectbox v-model="faculty_code"
                                               :validate-error="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="program_code" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('program')">
                            <program-selectbox v-model="formData.program_code"
                                               :faculty_code="faculty_code"
                                               :validate-error="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="semester_id" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('semester_1')">
                            <semesters-selectbox :validate-error="errors[0]"
                                                 v-model="formData.semester_id"/>
                        </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="semester_count" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('semester_count')">
                            <period-selectbox :validate-error="errors[0]"
                                              :to-range="5"
                                              v-model="formData.semester_count">
                            </period-selectbox>
                        </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="quota" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('quota')">
                            <b-form-input type="number"
                                          min="0" max="1000"
                                          v-check-min-max-value
                                          v-model="formData.quota"
                                          :state="errors[0] ? false : null"
                            />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="min_rank" rules="numeric" v-slot="{valid, errors}">
                        <b-form-group :label="$t('min_rank')">
                            <b-form-input type="number" min="0" v-check-min-max-value v-model="formData.min_rank"/>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
            </b-row>
            <div class="d-flex justify-content-center mt-5">
                <b-button variant="primary"
                          @click="updateForm"
                          :disabled="formLoading">
                    {{ $t('save') }}
                </b-button>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
    // Component
    import FacultySelectbox from '@/components/interactive-fields/FacultySelectbox'
    import ProgramSelectbox from '@/components/interactive-fields/ProgramSelectbox'
    import SemestersSelectbox from '@/components/interactive-fields/SemestersSelectbox'

    // Services
    import DoubleMajorQuotaService from "@/services/DoubleMajorQuotaService";

    // Other
    import {ValidationObserver, ValidationProvider} from 'vee-validate'

    export default {
        components: {
            FacultySelectbox,
            ProgramSelectbox,
            SemestersSelectbox,

            ValidationProvider,
            ValidationObserver
        },
        props: {
            formId: {
                type: Number
            }
        },
        data() {
            return {
                formLoading: false,
                faculty_code: null,
                formData: {
                    program_code: null,
                    semester_id: null,
                    quota: null,
                    point_type: null,
                    min_rank: null
                }
            }
        },
        async created() {
            await this.get()
        },
        methods: {
            get() {
                DoubleMajorQuotaService.detail(this.formId)
                                            .then(response => {
                                                let data = response.data.data

                                                this.faculty_code = data.program.faculty_code
                                                this.formData = {
                                                    program_code: data.program_code,
                                                    semester_id: data.semester_id,
                                                    quota: data.quota,
                                                    point_type: data.point_type
                                                }
                                            })
                                            .catch(() => {
                                                this.$router.push('/404')
                                            });
            },

            async updateForm() {
                //TODO: Check point_type from api
                const isValid = await this.$refs.formModalValidate.validate();
                if (isValid && this.formId) {
                    this.formLoading = true;
                    DoubleMajorQuotaService.update(this.formId, this.formData)
                                                .then(response => {
                                                    this.$emit('updateFormSuccess', true);
                                                    this.$toast.success(this.$t('api.' + response.data.message));
                                                })
                                                .catch(e => {
                                                    this.showErrors(e, this.$refs.formModalValidate);
                                                })
                                                .finally(() => {
                                                    this.formLoading = false
                                                })
                }
            }
        }

    }
</script>
